// src/app/app.component.ts
import { Component } from '@angular/core';

@Component({
selector: 'app-root',
templateUrl: './app.component.html',
styleUrls: ['./app.component.scss']  
// Removed: standalone: true
})
export class AppComponent {
title = 'landing-page';
}