import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import {
  Engine,
  Container,
  RecursivePartial,
  IOptions,
} from '@tsparticles/engine';
import { loadFull } from 'tsparticles';
import { environment } from '../../environments/environment';
import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { particleThemes, ThemeName } from './particle-themes';

/**
 * LandingPageComponent
 * This component handles the landing page's functionality, including particle animations,
 * Swiper carousel interactions, typewriter effect, and theme management.
 */
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LandingPageComponent implements OnInit, AfterViewInit {
  // ----------------------------
  // Public Properties
  // ----------------------------
  id = 'tsparticles';
  mainAppUrl = environment.mainAppUrl;
  cols = 3;
  activeSlide: string = 'AI-powered'; // Ensure this matches the slide IDs
  swiper!: Swiper;
  typingSpeed: number = 10; // Adjust typing speed here
  typedText: string = ''; // Initialize in your component
  defaultTheme: ThemeName = 'Interstellar Webcrawl';
  isToolbarVisible: boolean = false;
  toolbarVisibleClass: string = '';
  activeOverlayClass: string = 'overlay-ai-powered';
  particlesOptions: RecursivePartial<IOptions> | undefined;
  themes = Object.keys(particleThemes) as ThemeName[]; // Use the ThemeName type
  isThemeSelectorVisible: boolean = false;
  isGetStartedAnimationActive: boolean = false;

  // ----------------------------
  // ViewChild References
  // ----------------------------
  @ViewChild('getStartedWrapper') getStartedWrapper!: ElementRef;
  @ViewChild('dummyH1') dummyH1!: ElementRef;
  @ViewChild('realH1', { static: false }) realH1!: ElementRef;

  // ----------------------------
  // Typewriter Properties
  // ----------------------------
  typewriterCompleted: boolean = false;
  fullText: string = 'AI-powered workspace for long-context reasoning';

  // Define the structure of your h1 text
  h1Segments: { text: string; highlightClass?: string }[] = [
    { text: 'AI-powered', highlightClass: 'ai-powered' },
    { text: ' ', highlightClass: undefined },
    { text: 'workspace', highlightClass: 'workspace' },
    { text: ' for ', highlightClass: undefined },
    { text: 'long-context', highlightClass: 'long-context' },
    { text: ' ', highlightClass: undefined },
    { text: 'reasoning', highlightClass: 'reasoning' },
  ];

  // ----------------------------
  // Animation & State Properties
  // ----------------------------
  private hasAnimatedFirstSlide = false;
  isInitialAnimationComplete = false;
  isInitialToolbarComplete = true;

  // Particle Container Reference
  particlesContainer?: Container;

  // ----------------------------
  // Variables for Gesture Detection and Throttling
  // ----------------------------
  // Variables for Wheel Event Throttling
  private lastWheelEventTime = 0;
  private wheelEventCooldown = 1000; // in milliseconds

  // Variables for Touch Swipe Detection
  private touchStartY = 0;
  private touchEndY = 0;
  private touchThreshold = 50; // Minimum swipe distance in pixels

  // ----------------------------
  // Constructor
  // ----------------------------
  constructor(private cdr: ChangeDetectorRef) {}

  // ----------------------------
  // Host Listeners
  // ----------------------------
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setColsBasedOnWidth();
  }

  // HostListener for Wheel (Scroll) Events
  @HostListener('window:wheel', ['$event'])
  onWheel(event: WheelEvent) {
    const now = Date.now();
    if (now - this.lastWheelEventTime < this.wheelEventCooldown) {
      // Ignore the event if within cooldown period
      return;
    }

    if (event.deltaY > 0) {
      // Scroll Down - Navigate to Next Slide
      this.swiper.slideNext();
    } else if (event.deltaY < 0) {
      // Scroll Up - Navigate to Previous Slide
      this.swiper.slidePrev();
    }

    this.lastWheelEventTime = now;
  }

  // HostListener for Touch Start Events
  @HostListener('window:touchstart', ['$event'])
  onTouchStart(event: TouchEvent) {
    this.touchStartY = event.changedTouches[0].screenY;
  }

  // HostListener for Touch End Events
  @HostListener('window:touchend', ['$event'])
  onTouchEnd(event: TouchEvent) {
    this.touchEndY = event.changedTouches[0].screenY;
    this.handleSwipeGesture();
  }

  // Method to Handle Swipe Gesture
  private handleSwipeGesture() {
    const deltaY = this.touchStartY - this.touchEndY;

    if (Math.abs(deltaY) > this.touchThreshold) {
      if (deltaY > 0) {
        // Swipe Up - Navigate to Next Slide
        this.swiper.slideNext();
      } else {
        // Swipe Down - Navigate to Previous Slide
        this.swiper.slidePrev();
      }
    }
  }

  // ----------------------------
  // Angular Lifecycle Hooks
  // ----------------------------
  ngOnInit() {
    this.setColsBasedOnWidth();
    this.loadParticleOptions(this.defaultTheme);

    // Trigger particle creation on outside clicks
    document.addEventListener('click', (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest('.swiper-overlay')) {
        this.triggerParticleCreation(event);
      }
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.startTypewriterEffect(); // Start typewriter animation first

    }, 0);
  }

  // ----------------------------
  // Theme Management
  // ----------------------------
  onThemeChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    const selectedTheme = selectElement.value as ThemeName;
    console.log(`Theme selected: ${selectedTheme}`);
    this.loadParticleOptions(selectedTheme);

    this.cdr.detectChanges();
  }

  loadParticleOptions(theme: ThemeName) {
    this.particlesOptions = particleThemes[theme];
    this.updateParticles();
  }

  updateParticles(): void {
    if (this.particlesContainer) {
      this.particlesContainer.options.load(this.particlesOptions);
      this.particlesContainer.refresh();
    }
  }

  // ----------------------------
  // Typewriter Effect
  // ----------------------------
  startTypewriterEffect() {
    console.log('Starting typewriter effect...');

    const dummyH1Element = this.dummyH1?.nativeElement;
    const realH1Element = this.realH1?.nativeElement;

    if (!dummyH1Element) {
      console.error('dummyH1 not found in the DOM!');
      return;
    } else {
      console.log('dummyH1 found:', dummyH1Element);
    }

    if (!realH1Element) {
      console.error('realH1 not found in the DOM!');
      return;
    } else {
      console.log('realH1 found:', realH1Element);
    }

    // Make sure the realH1 is invisible but measurable
    realH1Element.style.visibility = 'hidden'; // Hide but measurable
    realH1Element.style.position = 'absolute'; // Off-screen but measurable
    realH1Element.style.top = '-9999px'; // Move off-screen to prevent layout shift

    // Measure the width of the realH1
    const realH1Width = realH1Element.offsetWidth; // Get width of fully rendered realH1
    const viewportWidth = window.innerWidth;

    console.log('realH1 width:', realH1Width);
    console.log('Viewport width:', viewportWidth);

    // Calculate the left margin needed to simulate center alignment
    const leftMargin = (viewportWidth - realH1Width) / 2;

    console.log('Calculated left margin for dummyH1:', leftMargin);

    // Apply this margin to the dummyH1 to simulate centering
    dummyH1Element.style.marginLeft = `${leftMargin}px`;

    console.log('Starting typewriter animation...');
    let textIndex = 0;

    const typeWriter = () => {
      if (textIndex < this.fullText.length) {
        this.typedText += this.fullText.charAt(textIndex);
        textIndex++;

        console.log(`Typing: ${this.typedText}`);

        const minTypingSpeed = 80;
        setTimeout(typeWriter, minTypingSpeed);
      } else {
        console.log('Typewriter effect complete. Switching to realH1...');

        this.typewriterCompleted = true;
        this.cdr.detectChanges();

        // Show the real h1 and hide the dummy h1
        realH1Element.style.visibility = 'visible'; // Make realH1 visible
        realH1Element.style.position = 'relative'; // Back to normal position
        realH1Element.style.top = '0'; // Reset top position
        dummyH1Element.style.display = 'none'; // Hide dummy h1

        // Log the transition to realH1
        console.log('realH1 is now visible, dummyH1 is hidden.');

        // Delay the toolbar visibility
        setTimeout(() => {
          this.toolbarVisibleClass = 'visible'; // Apply class to make toolbar visible
          this.cdr.detectChanges();

          // Initialize swiper and other animations if needed
          setTimeout(() => {
            this.isInitialAnimationComplete = true;
            this.cdr.detectChanges();
            this.initializeSwiper();

            console.log('Swiper initialized and keywords highlighted.');
          }, 2000);
        }, 1000); // Delay after typewriter completion
      }
    };

    typeWriter();
  }

  // ----------------------------
  // Swiper Initialization & Handlers
  // ----------------------------
  initializeSwiper(): void {
    this.swiper = new Swiper('.swiper', {
      modules: [Navigation, Pagination, Autoplay],
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      loop: true,
      autoplay: {
        delay: 15000,
        disableOnInteraction: false,
      },
      on: {
        slideChange: () => {
          if (this.swiper && this.swiper.realIndex !== undefined) {
            this.updateActiveSlide(this.swiper.realIndex);
  
            // Check if the active slide is "reasoning" (index 3 assuming 0-based index)
            if (this.swiper.realIndex === 3) {
              // Set flags after a delay equal to the bullet animations
              setTimeout(() => {
                this.isThemeSelectorVisible = true;
                this.isGetStartedAnimationActive = true;
                this.startAnimation(); 
                this.cdr.detectChanges();
              }, 3000); // Adjust delay to match bullet animation duration
            }
          }
        },
      },
    });
  }
  

  private updateActiveSlide(index: number | undefined): void {
    if (index === undefined) return;

    const slideKeywords = [
      'AI-powered',
      'workspace',
      'long-context',
      'reasoning',
      // Add more slide identifiers as needed
    ];

    this.activeSlide = slideKeywords[index % slideKeywords.length];
    const newClass = `overlay-${this.activeSlide}`;

    this.activeOverlayClass = newClass;

    console.log(
      `[${new Date().toLocaleTimeString()}] Active Slide updated to: ${this.activeSlide}`
    );
  }

  private resetBulletAnimations(): void {
    const slides = document.querySelectorAll('.swiper-slide');
    slides.forEach((slide) => {
      const bulletPoints = slide.querySelectorAll('.bullet-points li');
      bulletPoints.forEach((bullet, idx) => {
        const bulletElement = bullet as HTMLElement; // Cast to HTMLElement
        bulletElement.style.animation = 'none'; // Reset animation
        // Trigger reflow
        bulletElement.offsetHeight; // Access offsetHeight
        bulletElement.style.animation = `fadeInUp 0.6s forwards ${
          0.3 + idx * 0.2
        }s`; // Re-apply animation
      });
    });
  }

  private resetHighlights(): void {
    const highlightedKeywords = document.querySelectorAll('.keyword-highlight');
    highlightedKeywords.forEach((keyword) => {
      keyword.classList.remove('keyword-highlight');
    });
    console.log(
      `[${new Date().toLocaleTimeString()}] All keyword highlights have been reset.`
    );
  }

  private highlightKeywords(activeSlideId: string): void {
    const activeSlideElement = document.getElementById(activeSlideId);
    if (!activeSlideElement) {
      console.log(
        `[${new Date().toLocaleTimeString()}] No active slide found with ID: ${activeSlideId}`
      );
      return;
    }

    const keywords = activeSlideElement.querySelectorAll('.keyword');
    console.log(
      `[${new Date().toLocaleTimeString()}] All Keywords:`,
      keywords
    );

    keywords.forEach((keywordElement: Element, idx: number) => {
      setTimeout(() => {
        const spanElement = keywordElement as HTMLElement;
        spanElement.classList.add('keyword-highlight');
        console.log(
          `[${new Date().toLocaleTimeString()}] Highlighted keyword: "${spanElement.textContent}"`
        );
      }, idx * 500 + 1000); // 0.5-second delay between highlights, starting after 1 second
    });
  }

  // ----------------------------
  // Particle Interaction Handlers
  // ----------------------------
  private triggerParticleCreation(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (target.closest('.swiper-overlay')) {
      return; // Exit if the click is within the overlay
    }
    if (this.particlesContainer) {
      const rect = this.particlesContainer.canvas.element?.getBoundingClientRect();
      if (rect) {
        const posX = (event.clientX - rect.left) * window.devicePixelRatio;
        const posY = (event.clientY - rect.top) * window.devicePixelRatio;
        this.particlesContainer.particles.addParticle({
          x: posX,
          y: posY,
        });
      }
    }
  }

  async particlesInit(engine: Engine): Promise<void> {
    await loadFull(engine);
  }

  particlesLoaded(container: Container): void {
    this.particlesContainer = container;
  }

  // ----------------------------
  // Responsive Layout Handler
  // ----------------------------
  private setColsBasedOnWidth(): void {
    this.cols = window.innerWidth <= 960 ? 1 : 3;
  }

  // ----------------------------
  // Additional Animations
  // ----------------------------
  private startAnimation(): void {
    if (!this.getStartedWrapper) {
      return;
    }
    const wrapper = this.getStartedWrapper.nativeElement;
    let r2 = 360;
    let x = 100;
    const animate = () => {
      r2 = (r2 - 1 + 360) % 360;
      x = (x - 0.5 + 100) % 100;
      wrapper.style.setProperty('--r2', `${r2}deg`);
      wrapper.style.setProperty('--x', `${x}%`);
      requestAnimationFrame(animate);
    };
    requestAnimationFrame(animate);
  }
}
