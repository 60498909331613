// particle-themes.ts

import {
    RecursivePartial,
    } from '@tsparticles/engine';
    
    // Define a type for your theme names
    export type ThemeName =
    | 'Interstellar Webcrawl'
    | 'Nebula Swirl'
    | 'Cosmic Dust'
    | 'Galactic Stream'
    | 'Laser Burst'
    | 'Fireflies'
    | 'Nebular vortex'
    | 'Quantum Flux';
    
    // Define the particle themes using RecursivePartial
    export const particleThemes: Record<ThemeName, RecursivePartial<any>> = {
    'Interstellar Webcrawl': {
    background: {
    color: { value: 'transparent' },
    },
    fpsLimit: 120,
    interactivity: {
    events: {
    onHover: {
    enable: true,
    mode: ['grab', 'bubble', 'attract'],
    },
    onClick: {
    enable: false,
    },
    resize: {
    enable: true,
    },
    },
    modes: {
    grab: {
    distance: 350,
    links: {
    opacity: 0.7,
    color: '#32cd32',
    },
    },
    bubble: {
    distance: 200,
    size: 3,
    opacity: 0.95,
    duration: 1,
    },
    attract: {
    distance: 200,
    duration: 0.4,
    },
    },
    },
    particles: {
    color: {
    value: ['#3E3939', '#422A2A', '#468363', '#0C6AC8', '#ffffff'],
    },
    links: {
    enable: true,
    distance: 150,
    color: '#ffffff',
    opacity: 0.2,
    width: 1.8,
    twinkle: {
    lines: {
    enable: true,
    frequency: 0.25,
    opacity: 0.3,
    color: '#ff69b4',
    },
    },
    },
    move: {
    direction: 'top-right',
    enable: true,
    outModes: {
    default: 'bounce',
    },
    speed: { min: 0.2, max: 0.8 },
    attract: {
    enable: false,
    rotate: {
    x: 600,
    y: 1200,
    },
    },
    },
    number: {
    density: {
    enable: true,
    width: 800,
    height: 800,
    },
    value: 30,
    },
    opacity: {
    value: 0.1,
    animation: {
    enable: true,
    speed: 0.5,
    sync: false,
    },
    },
    shape: {
    type: 'circle',
    },
    size: {
    value: { min: 1, max: 3 },
    animation: {
    enable: true,
    speed: 2,
    sync: false,
    },
    },
    twinkle: {
    particles: {
    enable: true,
    frequency: 0.4,
    opacity: 0.3,
    color: '#ffd700',
    },
    },
    collisions: {
    enable: true,
    mode: 'bounce',
    },
    },
    detectRetina: true,
    parallax: {
    enable: false,
    force: 40,
    smooth: 40,
    },
    },
    'Nebula Swirl': {
  background: {
    color: { value: 'transparent' },
  },
  fpsLimit: 60,
  interactivity: {
    events: {
      onHover: {
        enable: true,
        mode: 'grab',
      },
      onClick: {
        enable: false,
        mode: 'bubble',
      },
      resize: {
        enable: true,
      },
    },
    modes: {
      grab: {
        distance: 200,
        lineLinked: {
          opacity: 0.5,
        },
      },
      bubble: {
        distance: 200,
        size: 10,
        duration: 2,
        opacity: 0.8,
      },
    },
  },
  particles: {
    color: {
      value: ['#a855f7', '#6d28d9', '#ec4899', '#3b82f6'],
    },
    links: {
      enable: false,
    },
    move: {
      enable: true,
      direction: 'none',
      outModes: {
        default: 'out',
      },
      speed: { min: 0.5, max: 2 },
      random: false,
      straight: false,
      attract: {
        enable: true,
        rotateX: 600,
        rotateY: 1200,
      },
    },
    number: {
      density: {
        enable: true,
        area: 800,
      },
      value: 40,
    },
    opacity: {
      value: { min: 0.3, max: 0.7 },
      animation: {
        enable: true,
        speed: 0.5,
        sync: false,
      },
    },
    shape: {
      type: 'circle',
    },
    size: {
      value: { min: 1, max: 4 },
      animation: {
        enable: true,
        speed: 0.5,
        sync: false,
      },
    },
  },
  detectRetina: true,
  parallax: {
    enable: false,
    force: 10,
    smooth: 10,
  },
},

        'Cosmic Dust': {
        background: {
        color: { value: 'transparent' },
        },
        fpsLimit: 60,
        interactivity: {
        events: {
        onHover: {
        enable: true,
        mode: 'bubble',
        },
        onClick: {
        enable: true,
        mode: 'remove',
        },
        resize: {
        enable: true,
        },
        },
        modes: {
        bubble: {
        distance: 300,
        size: 6,
        duration: 3,
        opacity: 0.9,
        },
        remove: {
        quantity: 2,
        },
        },
        },
        particles: {
        color: {
        value: ['#d4cdcd', '#c4afaf', '#b3b3b3'],
        },
        links: {
        enable: false,
        },
        move: {
        direction: 'bottom',
        enable: true,
        outModes: {
        default: 'out',
        },
        speed: { min: 0.5, max: 1.5 },
        straight: false,
        },
        number: {
        density: {
        enable: true,
        width: 800,
        height: 800,
        },
        value: 80,
        },
        opacity: {
        value: { min: 0.2, max: 0.5 },
        animation: {
        enable: true,
        speed: 0.5,
        sync: false,
        },
        },
        shape: {
        type: 'circle'
        },
        size: {
        value: { min: 1, max: 3 },
        animation: {
        enable: true,
        speed: 0.5,
        sync: false,
        },
        },
        twinkle: {
        particles: {
        enable: true,
        frequency: 0.05,
        opacity: 0.8,
        color: '#ffffff',
        },
        },
        collisions: {
        enable: false,
        },
        },
        detectRetina: true,
        parallax: {
        enable: true,
        force: 10,
        smooth: 15,
        },
        },
        'Galactic Stream': {
        background: {
        color: { value: 'transparent' },
        },
        fpsLimit: 90,
        interactivity: {
        events: {
        onHover: {
        enable: true,
        mode: 'connect',
        },
        onClick: {
        enable: true,
        mode: 'push',
        },
        resize: {
        enable: true,
        },
        },
        modes: {
        connect: {
        distance: 100,
        lineLinked: {
        opacity: 0.4,
        color: '#3E3939',
        },
        },
        push: {
        quantity: 2,
        },
        },
        },
        particles: {
        color: {
        value: '#ffffff',
        },
        links: {
        enable: true,
        distance: 100,
        color: '#ffffff',
        opacity: 0.3,
        width: 1,
        triangles: {
        enable: false,
        },
        },
        move: {
        direction: 'right',
        enable: true,
        outModes: {
        default: 'out',
        },
        speed: 0.5,
        straight: true,
        },
        number: {
        density: {
        enable: true,
        width: 800,
        height: 800,
        },
        value: 40,
        },
        opacity: {
        value: { min: 0.1, max: 0.6 },
        animation: {
        enable: true,
        speed: 0.3,
        sync: false,
        },
        },
        shape: {
        type: 'triangle',
        },
        size: {
        value: { min: 1, max: 3 },
        animation: {
        enable: true,
        speed: 0.8,
        sync: false,
        },
        },
        twinkle: {
        particles: {
        enable: false,
        },
        },
        collisions: {
        enable: false,
        },
        },
        detectRetina: true,
        parallax: {
        enable: false,
        force: 20,
        smooth: 20,
        },
        },
    'Laser Burst': {
    background: {
    color: { value: 'transparent' },
    },
    fpsLimit: 60,
    interactivity: {
    events: {
    onHover: {
    enable: true,
    mode: 'grab',
    },
    onClick: {
    enable: true,
    mode: 'push',
    },
    resize: {
    enable: true,
    },
    },
    modes: { push: {
      quantity: 5 // Creates 10 particles each time you click
    },
    repulse: {
    distance: 150,
    duration: 0.4,
    },
    },
    },
    particles: {
    color: {
    value: ['#ff4500', '#ffa500', '#ffff00'],
    },
    links: {
    enable: false,
    },
    move: {
    direction: 'outside',
    enable: true,
    outModes: {
    default: 'out',
    },
    speed: { min: 4, max: 8 },
    random: true,
    straight: false,
    },
    number: {
    density: {
    enable: false,
    width: 800,
    height: 800,
    },
    value: 15, // Start with no particles; explode creates them
    },
    opacity: {
    value: { min: 0.5, max: 1 },
    animation: {
    enable: true,
    speed: 1,
    sync: false,
    },
    },
    shape: {
    type: 'star',
    },
    size: {
    value: { min: 0.01, max: 0.05 },
    animation: {
    enable: true,
    speed: 1,
    sync: false,
    },
    },
    twinkle: {
    particles: {
    enable: false,
    },
    },
    collisions: {
    enable: false,
    },
    },
    detectRetina: true,
    parallax: {
    enable: true,
    force: 50,
    smooth: 25,
    },
    },
'Fireflies': {
background: {
color: { value: 'transparent' },
},
fpsLimit: 100,
interactivity: {
events: {
onHover: {
enable: true,
mode: ['trail'], // Retain the trail effect for firefly movement
},
onClick: {
enable: false,
mode: 'push', // Optional: You can remove or adjust if not needed
},
resize: {
enable: true,
},
},
modes: {
trail: {
delay: 0.5,
quantity: 5, // Retain the current trail settings
particles: {
color: {
value: '#ffd700', // Change to a warm yellow, mimicking fireflies
},
opacity: {
value: 1,
},
size: {
value: 0.4, // Keep the size consistent for a subtle effect
},
links: {
enable: false,
},
},
},
},
},
particles: {
move: {
direction: 'none',
enable: true,
outModes: {
default: 'out', // Ensures particles are removed when they leave the viewport
},
speed: { min: 1, max: 3 },
straight: false,
},
number: {
value: 5, // Remove initial particles for the firefly effect
density: {
enable: true,
width: 800,
height: 800,
},
},
opacity: {
value: { min: 0.3, max: 0.7 },
animation: {
enable: true,
speed: 0.5,
sync: false,
},
},
shape: {
type: 'circle',
},
size: {
value: { min: 0.4, max: 0.8 }, // Keep the global size consistent
animation: {
enable: true,
speed: 0.5,
sync: false,
},
},
twinkle: {
particles: {
enable: true,
frequency: 0.02,
opacity: 0.5,
color: '#ffffff',
},
},
collisions: {
enable: false,
},
},
detectRetina: true,
parallax: {
enable: true,
force: 15,
smooth: 10,
},
},

'Nebular vortex': {
  background: {
    color: { value: 'transparent' }, // Deep blue background to mimic the night sky
  },
  fpsLimit: 60,
  interactivity: {
    events: {
    onHover: {
    enable: true,
    mode: ['grab', 'repulse'],
    },
      onClick: {
        enable: true,
        mode: 'push', // Adds more stars on click
      },
      resize: true,
    },
    modes: {
      bubble: {
        distance: 200,
        size: 5,
        duration: 2,
        opacity: 0.8,
      },
      push: {
        quantity: 1,
      },
    },
  },
  particles: {
    color: {
      value: ['#ffffff', '#ffd700', '#87ceeb', '#1e3a8a'], // White, Gold, Sky Blue, Dark Blue
    },
    links: {
      enable: true,
      distance: 250, // Increased distance for longer links
      color: '#ffffff',
      opacity: 0.2,
      width: 1,
      // Adding curved links to emulate swirls
      type: 'curved',
    },
    move: {
      direction: 'inside',
      enable: true,
      outModes: {
        default: 'out',
      },
      speed: { min: 0.3, max: 1 }, // Variable speed for dynamic movement
      random: false,
      straight: false,
      attract: {
        enable: true,
        rotateX: 2600,
        rotateY: 2100,
      },
      // Adding an angle to create swirling motion
      angle: {
        value: 90, // Starting angle
        offset: 0,
        direction: 'clockwise', // Rotation direction
        speed: 8, // Controls the speed of the swirling motion
      },
    },
    number: {
      density: {
        enable: true,
        area: 800,
      },
      value: 15, // Increased number for a denser starry sky
    },
    opacity: {
      value: { min: 0.3, max: 0.8 },
      animation: {
        enable: true,
        speed: 0.5,
        minimumValue: 0.3,
        sync: false,
      },
    },
    shape: {
      type: ['circle', 'star'], // Mix of circles and stars for variety
      options: {
        star: {
          sides: 5, // 5-pointed stars
        },
      },
    },
    size: {
      value: { min: 1, max: 2 },
      animation: {
        enable: true,
        speed: 0.5,
        minimumValue: 1,
        sync: false,
      },
    },
    twinkle: {
      particles: {
        enable: true,
        frequency: 0.05,
        opacity: 1,
        color: '#ffffff',
      },
    },
    collisions: {
      enable: false, // Disable collisions to keep stars flowing smoothly
    },
  },
  detectRetina: true,
  parallax: {
    enable: true, // Enable parallax for depth effect
    force: 10,
    smooth: 15,
  },
},





    'Quantum Flux': {
    background: {
    color: { value: 'transparent' },
    },
    fpsLimit: 80,
     interactivity: {
    events: {
    onHover: {
    enable: true,
    mode: ['grab', 'bubble', 'attract'],
    },
    onClick: {
    enable: true,
    mode: 'push',
    },
    resize: {
    enable: true,
    },
    },
    modes: {
    grab: {
    distance: 100,
    links: {
    opacity: 0.5,
    color: '#8a2be2',
    },
    },
    push: {
    quantity: 6,
    },
    },
    },
    particles: {
    color: {
    value: ['#8a2be2', '#4b0082', '#9400d3'],
    },
    links: {
    enable: true,
    distance: 100,
    color: '#8a2be2',
    opacity: 0.4,
    width: 1,
    },
    move: {
    direction: 'none',
    enable: true,
    outModes: {
    default: 'out',
    },
    speed: { min: 1, max: 3 },
    straight: false,
    },
    number: {
    density: {
    enable: true,
    width: 800,
    height: 800,
    },
    value: 30,
    },
    opacity: {
    value: { min: 0.2, max: 0.6 },
    animation: {
    enable: true,
    speed: 0.3,
    sync: false,
    },
    },
    shape: {
    type: ['circle', 'square'],
    },
    size: {
    value: { min: 1, max: 4 },
    animation: {
    enable: true,
    speed: 0.7,
    sync: false,
    },
    },
    twinkle: {
    particles: {
    enable: true,
    frequency: 0.1,
    opacity: 0.8,
    color: '#ffffff',
    },
    },
    collisions: {
    enable: false,
    },
    },
    detectRetina: true,
    parallax: {
    enable: false,
    force: 25,
    smooth: 20,
    },
    },
    // Add more themes as needed
    };