<!-- Particles Background -->
<div class="ng-particles">
  <ngx-particles
  [id]="id"
  [options]="particlesOptions"
  [particlesInit]="particlesInit"
  (particlesLoaded)="particlesLoaded($event)"
></ngx-particles>
  <div class="overlay" [ngClass]="activeOverlayClass"></div>
</div>

<!-- Toolbar -->
<mat-toolbar color="primary" [ngClass]="toolbarVisibleClass"> <!-- Use ngClass -->
  <span>Slate</span>
  <span class="spacer"></span>
  <nav>
    <div class="get-started-wrapper" #getStartedWrapper>
      <a [href]="mainAppUrl + '/app/login'" class="get-started-button" color="accent">Get Started</a>
    </div>
  </nav>
</mat-toolbar>

<!-- Hero Section with Tagline and Swiper Carousel -->
<section class="hero">
  <!-- Tagline -->
  <h1 *ngIf="!typewriterCompleted" #dummyH1 class="dummy-typewriter">
    <span [innerHTML]="typedText"></span><span class="cursor"></span>
  </h1>

  <!-- Real H1, initially hidden but measurable -->
  <h1 #realH1 class="real-h1">
    <span class="highlight ai-powered" [class.highlight]="activeSlide === 'AI-powered'">AI-powered</span>
    <span class="space"></span>
    <span class="highlight workspace" [class.highlight]="activeSlide === 'workspace'">workspace</span> for
    <span class="highlight long-context" [class.highlight]="activeSlide === 'long-context'">long-context</span>
    <span class="space"></span>
    <span class="highlight reasoning" [class.highlight]="activeSlide === 'reasoning'">reasoning</span>
  </h1>

  <!-- Swiper Container -->
  <div *ngIf="isInitialAnimationComplete" class="swiper mySwiper">
    <div class="swiper-wrapper">
      <!-- Slide 1: AI-powered -->
<!-- Slide 1: AI-powered -->
<div class="swiper-slide" id="AI-powered">
  <div class="slide-content ai-powered-slide">
    <ul class="bullet-points">
      <li>
        <span class="bullet">All Advanced LLMs:</span> Currently supports OpenAI's 
        <span class="keyword keyword-ai-powered">GPT-4o</span> and 
        <span class="keyword keyword-ai-powered">o1-mini</span>. Coming soon -
        <span class="keyword keyword-ai-powered">Gemini 1.5 pro</span> and 
        <span class="keyword keyword-ai-powered">Claude Sonnet 3.5</span>.
      </li>
      <li>
        <span class="bullet">Multimodal capabilities:</span> Combine 
        <span class="keyword keyword-ai-powered">text</span>,
        <span class="keyword keyword-ai-powered">speech</span> and 
        <span class="keyword keyword-ai-powered">images</span> in the same input query. Output is limited to text and speech(
        <span class="keyword keyword-ai-powered">TTS</span>).
      </li>
      <li>
        <span class="bullet">Privacy focus:</span> Slate 
        <span class="keyword keyword-ai-powered">never</span> uses your data in any way. Slate is built to handle data privacy and security at scale.
      </li>
    </ul>
  </div>
</div>

<!-- Slide 2: Workspace -->
<div class="swiper-slide" id="workspace">
  <div class="slide-content workspace-slide">
    <ul class="bullet-points">
      <li>
        <span class="bullet">Second brain:</span> Manage all your 
        <span class="keyword keyword-workspace">notes</span>, 
        <span class="keyword keyword-workspace">tasks</span>,
        <span class="keyword keyword-workspace">code</span>, and  
        <span class="keyword keyword-workspace">knowledge artifacts</span> in one place.
      </li>
      <li>
        <span class="bullet">Creative Canvas:</span> Elevate creativity with Slate's 
        <span class="keyword keyword-workspace">Canvas</span> that lets you 
        <span class="keyword keyword-workspace">freehand draw</span> or 
        <span class="keyword keyword-workspace">annotate images</span> to brainstorm with AI.

      </li>
      <li>
        <span class="bullet">Flexible workspace:</span> Manage complex projects and workflows easily with powerful
        <span class="keyword keyword-workspace">project management</span> and
        <span class="keyword keyword-workspace">calendar</span> features.
      </li>
    </ul>
  </div>
</div>

<!-- Slide 3: Long-context -->
<div class="swiper-slide" id="long-context">
  <div class="slide-content long-context-slide">
    <ul class="bullet-points">
      <li>
        <span class="bullet">Multimodal reasoning:</span> Handles up to 
        <span class="keyword keyword-long-context">100k multimodal</span> tokens (
        <span class="keyword keyword-long-context">75,000 words</span> and 
        <span class="keyword keyword-long-context">50 HD images</span>) in a single query.
      </li>
      <li>
        <span class="bullet">Contextual AI responses:</span> Eliminate 
        <span class="keyword keyword-long-context">context-switching</span> as you can query LLMs directly within your workspace. 
      </li>
      <li>
        <span class="bullet">Ultra long contexts:</span> Coming soon -
        <span class="keyword keyword-long-context">1 million multimodal</span> token context window with
        <span class="keyword keyword-long-context">Gemini 1.5 pro</span>.
      </li>
    </ul>
  </div>
</div>

<!-- Slide 4: Reasoning -->
<div class="swiper-slide" id="reasoning">
  <div class="slide-content reasoning-slide">
    <ul class="bullet-points">
      <li>
        <span class="bullet">Advanced reasoning:</span> Unlock 
        <span class="keyword keyword-reasoning">chains</span> of advanced reasoning across your knowledge-base using 
        <span class="keyword keyword-reasoning">generative AI</span>.
      </li>
      <li>
        <span class="bullet">Granular controls:</span> Combine LLMs, customize 
        <span class="keyword keyword-reasoning">instructions</span>,
        <span class="keyword keyword-reasoning">response formatting</span> and
        <span class="keyword keyword-reasoning">manually save</span> conversation messages.
        <li>
          <span class="bullet">Diverse applications:</span> Ideal for
          <span class="keyword keyword-reasoning">programming</span>, 
        <span class="keyword keyword-reasoning">academic research</span>, 
        <span class="keyword keyword-reasoning">legal analysis</span> and other knowledge domains.
      </li>
    </ul>
  </div>
</div>
</div>


    <!-- Swiper Pagination -->
    <div class="swiper-pagination"></div>

    <!-- Swiper Navigation Buttons -->
    <div class="swiper-controls-bottom">
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>

    <!-- Swiper Overlay -->
    <div class="swiper-overlay"></div>
  </div>
  <div class="theme-selector" *ngIf="isThemeSelectorVisible">
    <label for="theme-select">Choose Theme:</label>
    <select id="theme-select" (change)="onThemeChange($event)">
      <option *ngFor="let theme of themes" [value]="theme">{{ theme }}</option>
    </select>
  </div>
  

</section>
